/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import CSVReader from 'react-csv-reader'
import { AgGridReact } from 'ag-grid-react' // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'

import './style.css'
import 'toastr/build/toastr.min.css'
import toastr from 'toastr'
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Input,
} from 'reactstrap'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'slices'
import { ScanActionButton } from 'Components/ScanActionButton/ScanActionButton'
import { io, Socket } from 'socket.io-client'
import { COUNTRY_FLAGS } from 'common/utils'

import { ModuleRegistry } from '@ag-grid-community/core'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { AmazonSellerConnectButton } from 'Components/AmazonSellerConnectButton/AmazonSellerConnectButton'

ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  RowGroupingModule,
])

const INTEGER_FIELDS = ['Ventes estimées par mois']
const PRICE_FIELDS = [
  'Prix amazon',
  'Prix fournisseur',
  'Profit Unitaire',
  'Profit mensuel',
]
const FIELDS_TO_COPY = ['ASIN', 'EAN', 'UPC']
const HIDDEN_FIELDS = ['Lien amazon', 'Lien fournisseur', 'Image amazon']
const PERCENTAGE_FIELDS = ['ROI']
const ScanStatusCell = ({ params, socket }) => {
  const dispatch = useDispatch()
  const [scanProgressData, setScanProgressData] = useState(0)
  useEffect(() => {
    socket?.on('SCAN_PROGRESS', (data) => {
      if (data.id === params.data._id.toString()) {
        setScanProgressData(data.progressPercentage)
        if (data.progressPercentage === 100) {
          dispatch(Store.scans.getOne(data.id) as any)
        }
      }
    })
  }, [])

  if (scanProgressData > 0) {
    if (scanProgressData === 100) {
      return 'Terminé'
    }
    return `En cours (${scanProgressData.toFixed(2)}%)`
  }
  if (params.value === 'processing') {
    return 'En cours'
  }
  if (params.value === 'completed') {
    return 'Terminé'
  }
  return 'En attente'
}

const DataPrewiewModal = ({
  fieldId,
  onHide,
}: {
  fieldId: string
  onHide: () => void
}) => {
  const [loading, setLoading] = useState(true)
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const init = useCallback(async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/scan/preview/?fileId=${fieldId}`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log('Response:', response)
      return response.data
    } catch (error) {
      toastr.error(error as string, 'Error')
    }
  }, [fieldId])
  useEffect(() => {
    if (!fieldId) return
    init()
      .then((data) => {
        console.log('---------------------------------------')

        console.log('Data:', data)
        const colDefs: any = ['Lien', ...Object.keys(data[0])]
          .map((key) =>
            HIDDEN_FIELDS.includes(key)
              ? null
              : {
                  headerName: key,
                  field: key,
                  cellRenderer:
                    key === 'Lien'
                      ? (params) => {
                          if (params.colDef.field === 'Lien') {
                            return (
                              <a
                                href={params.data['Lien amazon']}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  width="20px"
                                  height="20px"
                                  src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg"
                                />
                              </a>
                            )
                          }
                        }
                      : undefined,
                  filter:
                    INTEGER_FIELDS.includes(key) ||
                    PRICE_FIELDS.includes(key) ||
                    PERCENTAGE_FIELDS.includes(key)
                      ? 'agNumberColumnFilter'
                      : true,
                  valueFormatter: (params) => {
                    if (INTEGER_FIELDS.includes(params.colDef.field)) {
                      return parseInt(params.value, 10)
                    }
                    if (PERCENTAGE_FIELDS.includes(params.colDef.field)) {
                      return `${parseFloat(params.value).toFixed(2)}%`
                    }
                    if (PRICE_FIELDS.includes(params.colDef.field)) {
                      return `${parseFloat(params.value).toFixed(2)} €`
                    }
                    if (params.colDef.field === 'Marketplace') {
                      return COUNTRY_FLAGS[params.value]
                    }
                    return params.value
                  },
                }
          )
          .filter((col) => col !== null)
        setColumnDefs(colDefs)
        setRowData(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error:', error)
      })
  }, [fieldId, init])

  console.log({ loading })

  return (
    <Modal
      size="xl"
      fullscreen="lg"
      onClosed={onHide}
      isOpen={Boolean(fieldId)}
    >
      <ModalHeader closeButton>
        <h3>Votre scan traité</h3>
      </ModalHeader>
      <ModalBody>
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: 500, marginBottom: 50 }} // the Data Grid will fill the size of the parent container
        >
          {!loading ? (
            <AgGridReact
              defaultColDef={{
                sortable: true,
                filter: true,
                width: '100%' as any,
              }}
              rowHeight={50}
              rowData={rowData} // Replace with actual data
              columnDefs={columnDefs} // Replace with actual column definitions
              onCellClicked={(row) => {
                if (FIELDS_TO_COPY.includes(row.colDef.field as any)) {
                  navigator.clipboard.writeText(row.value)
                  toastr.success('Copied to clipboard')
                }
              }}
            />
          ) : (
            <div>Chargement en cours...</div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const ScanCollection: React.FC<{
  // props
}> = () => {
  const dispatch = useDispatch()
  const user: any = useSelector(Store.login.selectors.user)
  const scans = useSelector(Store.scans.selectors.getAllCollection)
  const [csvData, setCsvData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [selectedFieldToPreview, setSelectedFieldToPreview] = useState('')
  const [fileHeaders, setFileHeaders] = useState([])
  const [file, setFile] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [vatRate, setVatRate] = useState(0)
  const [numberOfRows, setNumberOfRows] = useState(0)
  const [vatRegistered, setVatRegistered] = useState(false)
  const socketIO = useRef<Socket>()
  const [columns, setColumns] = useState<any>([
    {
      headerName: 'ASIN',
      field: 'asin',
    },
    {
      headerName: 'title',
      field: 'title',
    },
    {
      headerName: 'Prix',
      field: 'price',
    },
  ])

  const scanFields = [
    {
      headerName: 'Nom du fichier',
      field: 'orginalFileName',
      flex: 1,
      cellRenderer: ((params): any => (
        <span>
          <i className="fa fa-eye" /> {params.value}
        </span>
      )) as any,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params) =>
        (<ScanStatusCell params={params} socket={socketIO.current} />) as any,
      cellStyle: (params) => {
        console.log('Params:', params.column.colId)

        let styles: any = {
          overflow: 'visible',
          color: 'blue',
        }
        if (params.value === 'completed') {
          styles = { ...styles, color: 'green' }
        }
        if (params.value === 'processing') {
          styles = { ...styles, color: 'orange' }
        }
        return styles
      },
    },
    {
      headerName: "Durée d'exécution",
      field: 'actions',
      cellRenderer: ({ data }) => {
        const startTime = new Date(data.processingStartAt)
        const endTime = new Date(data.processingEndAt)
        const diff = endTime.getTime() - startTime.getTime()
        const seconds = Math.floor(diff / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        return data.status === 'completed'
          ? `${hours}h ${minutes % 60}m ${seconds % 60}s`
          : 'N/A'
      },
      flex: 1,
    },
    {
      headerName: 'Date de création',
      field: 'dateOfCreation',
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      cellRenderer: ScanActionButton,
      cellStyle: {
        overflow: 'visible',
      },
    },
  ]

  const identifiedColumns = [
    {
      headerName: 'ASIN/EAN/UPC',
      field: 'id',
    },
    {
      headerName: 'title',
      field: 'title',
    },
    {
      headerName: 'Prix',
      field: 'price',
    },
  ]
  const [selectedColumns, setSelectedColumns] = useState({
    id: '',
    title: '',
    price: '',
  })

  useEffect(() => {
    socketIO.current = io(
      (process.env.REACT_APP_API_URL as string)
        .replace('http', 'ws')
        .replace('https', 'ws'),
      {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 3,
        transports: ['websocket'],
      }
    )
    socketIO.current.connect()
    socketIO.current.on('connect', () => {
      console.log('-----------------------------------')
      console.log('Connected')
    })
    socketIO.current.on('SCAN_START', (data) => {
      console.log('Scan Started:', data)

      dispatch(Store.scans.getOne(data.id) as any)
    })
    socketIO.current.on('SCAN_END', () => {
      console.log('Scan Ended')

      dispatch(Store.scans.fetch() as any)
    })

    dispatch(Store.scans.fetch() as any)

    return () => {
      socketIO?.current?.disconnect()
    }
  }, [])

  const handleFileUpload = (data, fileinfo, originalFile) => {
    if (fileinfo.type !== 'text/csv') {
      toastr.error('Invalid file format')
      return
    }
    if (!data || data.length <= 0) {
      toastr.error('File is empty')
      return
    }
    setFile(originalFile)

    const [header, ...rowData] = data
    if (rowData.length >= 25001) {
      toastr.error(
        'Votre fichier est trop large, un fichier accepté doit faire au max 2500 produits'
      )
      return
    }
    setFileHeaders(header)
    const colDefs = header.map((key) => ({
      headerName: key,
      field: key,
      valueFormatter: (params) => {
        if (INTEGER_FIELDS.includes(params.colDef.field)) {
          return parseInt(params.value, 10)
        }
        return params.value
      },
    }))
    setNumberOfRows(rowData.length)
    const formattedData = rowData
      .map((row) => {
        const obj = {}
        if (row.length <= 0 || row.every((e) => e.length === 0)) return null
        header.forEach((key, index) => {
          obj[key] = row[index]
        })
        return obj
      })
      .filter((row) => row !== null)
    setCsvData(formattedData)
    setColumns(colDefs)
    setShowModal(true)
    dispatch(Store.scans.fetch() as any)
  }

  const onClose = () => {
    setShowModal(false)
    setCsvData([])
    setColumns([])
    setSelectedColumns({
      id: '',
      title: '',
      price: '',
    })
    dispatch(Store.scans.fetch() as any)
  }

  const handeColumnChange = (columnName: 'id' | 'price' | 'title') => (e) => {
    setSelectedColumns({
      ...selectedColumns,
      [columnName]: e.target.value,
    })
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      console.log('isSubmitting, true')

      if (
        !selectedColumns.id ||
        !selectedColumns.price ||
        !selectedColumns.title ||
        numberOfRows <= 0 ||
        !file
      ) {
        toastr.error('Please select all columns')
        return
      }
      if (!file) {
        toastr.error('Please upload a file')
        return
      }
      console.log({ file })

      const formData = new FormData()
      const indexes = Object.values(selectedColumns).reduce((acc, key) => {
        // const keyIndex = selectedColumns[key]
        const keyIndex = fileHeaders.indexOf(key as never)
        console.log('Key:', key, 'Index:', keyIndex)

        acc[key] = keyIndex
        return acc
      }, {} as any)

      console.log('Indexes:', indexes)
      console.log('Selected Columns:', selectedColumns)
      console.log(fileHeaders)

      formData.append('file', file)
      formData.append('columns', JSON.stringify(selectedColumns))
      formData.append('vatRate', vatRegistered ? vatRate.toString() : '0')
      formData.append('numberOfProducts', numberOfRows.toString())
      formData.append('columnIndexes', JSON.stringify(indexes))
      const token = localStorage.getItem('token')
      console.log('Token:', token)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      // const response = await fetch(
      //   `${process.env.REACT_APP_API_URL}/products/upload`,
      //   {
      //     method: 'POST',
      //     body: formDate,
      //   }
      // )

      console.log('Response:', response)
      onClose()
    } catch (error) {
      console.log('Error:', error)
    } finally {
      console.log('isSubmitting, false')

      setSubmitting(false)
      dispatch(Store.scans.fetch() as any)
    }
  }

  console.log({ isSubmitting })

  return (
    <div className="page-content">
      <AmazonSellerConnectButton />
      <Button
        onClick={() => {
          if (user.isSellerApiConnected || user.isAdmin) {
            setShowModal(true)
          } else {
            toastr.error(
              'Veuillez connecter votre compte Amazon Seller pour continuer'
            )
          }
        }}
      >
        Ajouter un nouveau scan
      </Button>
      <DataPrewiewModal
        onHide={() => setSelectedFieldToPreview('')}
        fieldId={selectedFieldToPreview}
      />

      <Modal
        size="xl"
        fullscreen="lg"
        isOpen={showModal}
        onHide={() => setShowModal(false)}
      >
        <ModalHeader closeButton>
          <h3>Ajout de Scan</h3>
        </ModalHeader>
        {csvData.length > 0 ? (
          <ModalBody>
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: 500, marginBottom: 50 }} // the Data Grid will fill the size of the parent container
            >
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  filter: true,
                  width: '100%' as any,
                }}
                rowHeight={50}
                rowData={csvData}
                columnDefs={columns}
              />
            </div>
            <div className="row">
              {identifiedColumns.map((identifiedColumn) => (
                <div
                  key={`form-${identifiedColumn.field}`}
                  className="col-md-6 col-lg-4"
                >
                  <FormGroup key={`form-${identifiedColumn.field}`}>
                    <Label>
                      Selectionner colonne: {identifiedColumn.headerName}
                    </Label>
                    <Input
                      type="select"
                      value={selectedColumns[identifiedColumn.field]}
                      onChange={handeColumnChange(
                        identifiedColumn.field as any
                      )}
                    >
                      <option value="">Select...</option>
                      {csvData.length > 0 &&
                        columns.map((key) => (
                          <option key={key.field} value={key.field}>
                            {key.headerName}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <FormGroup switch>
                  <Input
                    onClick={() => {
                      setVatRegistered(!vatRegistered)
                    }}
                    checked={vatRegistered}
                    type="switch"
                  />{' '}
                  <Label check>TVA Enrégistrée ?</Label>
                </FormGroup>
              </div>
            </div>
            {vatRegistered && (
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <FormGroup>
                    <Label for="exampleRange">
                      {`TVA sur le prix d'achat`} ({vatRate}%)
                    </Label>
                    <Input
                      onChange={(e) => {
                        setVatRate(parseInt(e.target.value, 10))
                      }}
                      value={vatRate}
                      id="exampleRange"
                      name="range"
                      type="range"
                    />
                  </FormGroup>
                </div>
              </div>
            )}
          </ModalBody>
        ) : (
          <ModalBody>
            <CSVReader
              cssClass="form-control"
              onError={(e) => console.error('Error:', e)}
              onFileLoaded={handleFileUpload}
            />
          </ModalBody>
        )}
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            disabled={
              !selectedColumns.id ||
              !selectedColumns.price ||
              !selectedColumns.title ||
              numberOfRows <= 0 ||
              !file ||
              isSubmitting
            }
            color="primary"
            onClick={handleSubmit}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>
      <h1>Liste des scans</h1>

      <Row>
        <div
          className="ag-theme-quartz"
          style={{
            height: 700,
            marginBottom: 50,
          }}
        >
          <AgGridReact<any>
            defaultColDef={{
              sortable: true,
              filter: true,
              width: '100%' as any,
            }}
            onCellClicked={(row) => {
              if (
                row.colDef.field !== 'orginalFileName' ||
                row.data.status !== 'completed'
              ) {
                return
              }
              setSelectedFieldToPreview(row.data._id)
            }}
            rowData={scans}
            columnDefs={scanFields}
          />
        </div>
      </Row>
    </div>
  )
}
