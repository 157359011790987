import React, { useEffect } from 'react'
import * as R from 'ramda'
import { Container, CardBody, Row } from 'reactstrap'
import { Filters } from 'Components/Filters/Filters'
import { ResultTable } from 'Components/ResultTable/ResultTable'
import * as data from 'data'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import { CustomPagination } from 'Components/CustomPagination/CustomPagination'
import toastr from 'toastr'
import { getSearchConditionMongoose } from './searchHelper'

// const { parse } = require('json2csv')

const Dashboard = () => {
  document.title = 'Dashboards | ResellCorner'
  const resultRef = React.useRef<any>()
  const suppliers = useSelector(Store.suppliers.selectors.getAllCollection)
  const supplerIndexByType = R.groupBy(R.prop('type') as any, suppliers)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [result, setResult] = React.useState<any>({})
  const [conditions, setConditions] = React.useState<any>({})
  const user: any = useSelector(Store.login.selectors.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Store.login.fetchUser(user._id) as any)
  }, [user._id])
  useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers() as any)
  }, [])
  const paginateSearch = (page: number, poConditions?: any) => {
    if (!conditions) {
      return
    }
    setLoading(true)
    data.product
      .findByConditions('search', {
        conditions: poConditions || conditions,
        paginationOption: {
          page,
        },
        group: {
          _id: '$asin',
          products: {
            $push: '$$ROOT',
          },
          count: {
            $sum: 1,
          },
        },
        sort: {
          profit: -1,
          lastUpdateDate: -1,
        },
      })
      .then((res) => {
        setResult(res.data)

        setLoading(false)
      })
      .catch((err) => {
        console.log({ err })
        if (
          err === 'Invalid credentials' ||
          err === 'Wrong authentication token'
        ) {
          dispatch(Store.login.logoutUser() as any)
        } else {
          toastr.error(
            'Une erreur est survenue lors de la recherche. Réessayez.'
          )
        }
        setResult({})
        setLoading(false)
      })
  }
  const onSearch = (values) => {
    if (!values) {
      return
    }
    if ((values?.suppliers || [])?.length === 0) {
      toastr.error('⚠️ Merci d’ajouter au moins 1 fournisseur à la recherche')
      return
    }
    if (Object.keys(values).length === 0) {
      return
    }

    setLoading(true)
    const searchConditions = getSearchConditionMongoose(
      values,
      supplerIndexByType
    )
    setConditions(searchConditions)
    paginateSearch(1, searchConditions)
  }

  return (
    <div className="page-content">
      <div ref={resultRef}>
        <Filters
          onCSVParse={async () => {
            try {
              const content = await data.product.post('download', conditions)
              const blob = new Blob([content], { type: 'text/csv' })
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = `${new Date().getTime()}-exported_data.csv`
              a.click()
              window.URL.revokeObjectURL(url)
            } catch (error) {
              console.log({ error })
            }
          }}
          onSubmit={onSearch}
        />
        <Row className="mt-20">
          <CustomPagination
            pagesCount={result?.totalPages || 1}
            currentPage={result?.page || 1}
            handlePageClick={(_, i) => paginateSearch(i)}
          />
          <div className="col-12">
            Nombre de produits: {result?.totalDocs || 0}
          </div>
        </Row>
      </div>
      <Container fluid>
        <CardBody>
          <ResultTable
            allowNonProfit
            isLoading={loading}
            tableResult={result?.docs || []}
          />
          <Row>
            <CustomPagination
              pagesCount={result?.totalPages || 1}
              currentPage={result?.page || 1}
              handlePageClick={(_, i) => paginateSearch(i)}
            />
          </Row>
        </CardBody>
      </Container>
    </div>
  )
}

export default Dashboard
